import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { sum } from 'lodash';
import {
  getOrCreateTransdimensionalGridSelector,
  getOrCreateTransdimensionalRectSelector,
} from '../../selectors';
import { useSpacing } from '../../hooks';
import { ThreeQuad, ThreeTransdimensionalRect } from '.';

export const ThreeTransdimensionalGrid: FC<{ id: string }> = (props) => {
  const gridId = props.id;
  const rect = useSelector(getOrCreateTransdimensionalRectSelector(gridId));
  const grid = useSelector(getOrCreateTransdimensionalGridSelector(gridId));

  if (!rect) return null;
  if (!grid) return null;

  const spacing = useSpacing(grid.gridContainerConfiguration.spacing);
  const spacingWidthRatio = spacing / rect.width;

  const childIndexes = Object.keys(grid.gridItemSizes).sort();
  const gridItemSizes = childIndexes.map(
    (childIndex) => grid.gridItemSizes[childIndex as any]
  );

  const rows: number[][] = [];
  let currentRow: number[] = [];
  let rowIndex = 0;

  gridItemSizes.forEach((gridItemSize, index) => {
    let column = sum(currentRow);
    const isRowOverflow = column + gridItemSize > 12;

    if (isRowOverflow) {
      rows[rowIndex] = currentRow;
      rowIndex++;
      currentRow = [];
      column = 0;
    }

    currentRow.push(gridItemSize);
  });

  if (currentRow.length > 0) {
    rows[rowIndex] = currentRow;
  }

  const childElements: ReactNode[] = [];

  rows.forEach((row, rowIndex) => {
    const gridItemSizeWidth = (rect.width - (row.length - 1) * spacing) / 12;
    const gridItemSizeWidthRatio = gridItemSizeWidth / rect.width;

    let column = 0;

    row.forEach((gridItemSize, rowItemIndex) => {
      column += gridItemSize;

      if (column === 12) return;

      childElements.push(
        <ThreeQuad
          key={`${rowIndex}_${rowItemIndex}`}
          position={[
            -0.5 +
              column * gridItemSizeWidthRatio +
              rowItemIndex * spacingWidthRatio +
              spacingWidthRatio / 2,
            0,
            0,
          ]}
          scale={[spacingWidthRatio, 1, 1]}
        />
      );
    });
  });

  return (
    <ThreeTransdimensionalRect id={props.id}>
      {/* {childElements} */}
    </ThreeTransdimensionalRect>
  );
};
