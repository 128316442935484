import { lighten } from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import { BRAND_COLOR_RED, BRAND_COLOR_ORANGE } from '../../constants';
import { Project } from '../../types';

export const PROJECT_SAMUELGOODELL_CALENDLY_PROXY: Project = {
  id: '@samuelgoodell/calendly-proxy',
  name: 'Calendly Proxy',
  description:
    'Proxy for the Calendly API which securely uses my personal access token',
  status: 'complete',
  chip: {
    background: `linear-gradient(135deg, ${BRAND_COLOR_RED} 0%, ${lighten(
      BRAND_COLOR_ORANGE,
      0.15
    )} 100%)`,
    color: 'rgb(255, 255, 255)',
    content: '@samuelgoodell/calendly-proxy',
  },
  page: {
    tabs: [
      {
        label: 'Overview',
        page: {
          markdown: `\
### Objectives

- Enable seamless integration of my [Calendly](https://calendly.com) introduction meeting booking through a self-branded page on my [Portfolio website](https://samuelgoodell.com).
- Must not expose my Calendly personal access token to users.

### Status

This project is complete and in maintenance.
`,
        },
      },
      {
        icon: <CodeOutlinedIcon />,
        label: 'Repository',
        href: 'https://gitlab.com/goodells/calendly-proxy',
      },
      {
        icon: <OpenInNewOutlinedIcon />,
        label: 'Live Demo',
        href: 'https://samuelgoodell.com/meet',
      },
    ],
  },
};
