import { FC, ReactNode } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { TransdimensionalRect } from '../..';
import { ResumeSection, ResumeSectionLabel, ResumeDivider } from '.';
import { getColorFilter } from '../../../utilities';

const ResumeEducationItem: FC<{
  id: string;
  institution: ReactNode;
  institutionLogo?: ReactNode;
  degree: ReactNode;
  completion: ReactNode;
}> = (props) => {
  const theme = useTheme();

  return (
    <TransdimensionalRect
      id={`resumeDocument_educationItem_${props.id}`}
      display="block"
      sx={{ paddingLeft: props.institutionLogo ? 5.5 : 0 }}
    >
      {props.institutionLogo && (
        <Box
          component="div"
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: theme.spacing(4),
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.institutionLogo}
        </Box>
      )}
      <Typography component="span" variant="body1" fontWeight="bold">
        {props.institution}
      </Typography>
      <Typography component="span" variant="body2">
        {' '}
        — {props.completion}
      </Typography>
      <Typography variant="body1">{props.degree}</Typography>
    </TransdimensionalRect>
  );
};

export const ResumeEducationSection: FC = () => {
  const theme = useTheme();

  return (
    <ResumeSection
      sideContent={<ResumeSectionLabel>Education</ResumeSectionLabel>}
    >
      <Stack spacing={1}>
        <ResumeEducationItem
          id="universityOfWisconsin"
          institution="University of Wisconsin - Madison"
          institutionLogo={
            <img
              src="/images/uw-crest-outline.png"
              width="100%"
              style={{
                transform: 'scale(0.9)',
                filter: getColorFilter(theme.palette.text.primary),
              }}
            />
          }
          degree="Bachelor of Science, Computer Sciences"
          completion="May 2018"
        />
        <ResumeEducationItem
          id="madisonCollege"
          institution="Madison Area Technical College"
          institutionLogo={
            <img
              src="/images/matc-crest.png"
              width="100%"
              style={{
                transform: 'scale(0.9)',
                filter: getColorFilter(theme.palette.text.primary),
              }}
            />
          }
          degree="Paramedic Technical Diploma"
          completion="December 2023"
        />
      </Stack>
      <ResumeDivider />
    </ResumeSection>
  );
};
