import { FC, PropsWithChildren, ReactNode } from 'react';

import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  SxProps,
  Theme,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { TransdimensionalUnitConversionServiceContext } from '../../../contexts';
import {
  Badge,
  BrandCircle,
  Chip,
  ChipList,
  ChipThree,
  DocumentPage,
  Icon,
  LayoutAnchor,
  Logo,
  LogoFillVariant,
  NoWrap,
  ThemeInverter,
  ThemeProvider,
  Three,
  ThreeBox,
  ThreeLayer,
  ThreeLighting,
  ThreeModel,
  ThreeQuad,
  ThreeText,
  ThreeTransdimensionalRect,
  TransdimensionalOffset,
  TransdimensionalRect,
} from '../..';
import {
  LIGHT_THEME,
  DARK_THEME,
  CONTACT_EMAIL_ADDRESS,
  CONTACT_TELEPHONE_NUMBER,
  THREE_FULL_DEPTH,
  THREE_FULL_HEIGHT,
  THREE_FULL_WIDTH,
  BRAND_COLOR_YELLOW,
  BRAND_COLOR_YELLOW_CONTRAST,
  SERVICE_ITEMS_BY_ID,
  THREE_CAMERA_DEPTH,
} from '../../../constants';
import { useInvertedTheme, useMaterial } from '../../../hooks';
import { DocumentSize } from '../../../types';
import {
  emailAddressToHref,
  enhance,
  getColorFilter,
  telephoneNumberToHref,
  toColor,
} from '../../../utilities';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { Box3, Vector3 } from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { useSelector } from 'react-redux';
import { getOrCreateTransdimensionalRectSelector } from '../../../selectors';
import {
  ResumeContainer,
  ResumeSection,
  ResumeSectionLabel,
  ResumeDivider,
  ResumeHero,
  ResumeHeroThree,
  ResumeBiography,
  ResumeBiographyThree,
  ResumeMeet,
  ResumeMeetThree,
  ResumeEducationSection,
  ResumeSkillsSection,
  ResumeSkillsSectionThree,
  ResumeExperienceSectionPage1,
  ResumeExperienceSectionPage2,
} from '.';

export const RESUME_DOCUMENT_SIZE: DocumentSize = {
  width: '8.5in',
  height: '11in',
  aspectRatio: '8.5 / 11',
};

const ResumeThree: FC<
  PropsWithChildren<{
    pageNumber: number;
    layers?: ThreeLayer[];
  }>
> = ({ pageNumber, layers, children }) => {
  return (
    <Three
      id={`resumeDocument_three${pageNumber}`}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: layers && layers[0].zIndex,
      }}
      fovScaling={false}
      frameloop="demand"
      layers={layers}
      fogNear={10000}
      fogFar={10000}
    >
      <ambientLight intensity={0.875} />
      <directionalLight color="white" position={[10, 5, 7.5]} intensity={0.4} />
      <TransdimensionalUnitConversionServiceContext.Consumer>
        {(transdimensionalUnitConversionService) => {
          if (!transdimensionalUnitConversionService) return null;

          const sceneWidth =
            transdimensionalUnitConversionService.getVisibleWidthAtDepth(0);
          const sceneHeight =
            transdimensionalUnitConversionService.getVisibleHeightAtDepth(0);

          return <group position={[0, sceneHeight / 2, 0]}>{children}</group>;
        }}
      </TransdimensionalUnitConversionServiceContext.Consumer>
    </Three>
  );
};

const ResumeDocumentPage1Three: FC = () => {
  const theme = useTheme();
  const basicMaterial1 = useMaterial(theme.palette.background.object, {
    convertSRGBToLinear: true,
  });

  return (
    <>
      {/* <ThreeQuad
            scale={[THREE_FULL_WIDTH, THREE_FULL_HEIGHT, 1]}
            position={[0, 0, -THREE_FULL_DEPTH / 2]}
            rotation={[Math.PI / 2, 0, 0]}
          /> */}
      <ResumeHeroThree />
      <ResumeBiographyThree />
      <ResumeMeetThree />
      <ResumeSkillsSectionThree />
      <ThreeTransdimensionalRect id="resumeDocument_page1_sideContentTop">
        <ThreeBox
          position={[-1 / 2, -1 / 2, -theme.depth.card / 2]}
          scale={[2, 2, theme.depth.card]}
          rotation={[0, 0, 0]}
          material={basicMaterial1}
        />
      </ThreeTransdimensionalRect>
      <ThreeTransdimensionalRect id="resumeDocument_page1_sideContent">
        <ThreeBox
          position={[-1 / 2, -1 / 2, -theme.depth.card / 2]}
          scale={[2, 2, theme.depth.card]}
          rotation={[0, 0, 0]}
          material={basicMaterial1}
        />
      </ThreeTransdimensionalRect>
    </>
  );
};

const ResumeDocumentPage2Three: FC = () => {
  const theme = useTheme();
  const basicMaterial1 = useMaterial(theme.palette.background.object, {
    convertSRGBToLinear: true,
  });

  return (
    <>
      <ThreeTransdimensionalRect id="resumeDocument_page2_sideContent">
        <ThreeBox
          position={[-1 / 2, 1 / 2, -theme.depth.card / 2]}
          scale={[2, 4, theme.depth.card]}
          rotation={[0, 0, 0]}
          material={basicMaterial1}
        />
      </ThreeTransdimensionalRect>
    </>
  );
};

export const ResumeDocument: FC = () => {
  // const theme = useTheme();
  const theme = DARK_THEME;
  // const theme = LIGHT_THEME;
  const invertedTheme = useInvertedTheme(theme);
  const dividerColor = theme.palette.background.default;
  const matchesPrint = useMediaQuery('print');
  const basicMaterial1 = useMaterial(theme.palette.background.object, {
    convertSRGBToLinear: true,
  });

  return (
    <ThemeProvider theme={theme}>
      <DocumentPage
        documentSize={RESUME_DOCUMENT_SIZE}
        pageNumber={1}
        sx={{
          position: 'relative',
          backgroundColor: theme.palette.background.clear,
        }}
      >
        <ResumeThree
          pageNumber={1}
          layers={[
            {
              zIndex: -1,
              near: THREE_CAMERA_DEPTH - 0.05,
              far: 1000,
            },
          ]}
        >
          <ResumeDocumentPage1Three />
        </ResumeThree>
        <Box component="div">
          <ResumeHero />
          <ResumeBiography />
          <ResumeContainer>
            <Box component="div" sx={{ position: 'relative', marginTop: 2 }}>
              <TransdimensionalRect
                id="resumeDocument_page1_sideContentTop"
                sx={{
                  position: 'absolute',
                  left: theme.spacing(-4),
                  top: 0,
                  width: `calc(${theme.spacing(4)} + 70px)`,
                  height: '100%',
                }}
              />
              <ResumeMeet />
              <TransdimensionalRect
                id="resumeDocument_page1_sideContent"
                sx={{
                  position: 'absolute',
                  left: theme.spacing(-4),
                  top: 0,
                  width: `calc(${theme.spacing(4)} + 70px)`,
                  height: '100%',
                }}
              />
              <ResumeEducationSection />
              <ResumeSkillsSection />
              <ResumeExperienceSectionPage1 />
            </Box>
          </ResumeContainer>
          <Box component="div" sx={{ color: theme.palette.text.secondary }}>
            <ArrowDownwardOutlinedIcon
              fontSize="large"
              color="inherit"
              sx={{
                position: 'absolute',
                left: `calc(${theme.spacing(4)} + 7px)`,
                bottom: theme.spacing(2),
                opacity: 0.5,
              }}
            />
          </Box>
        </Box>
        <ResumeThree
          pageNumber={1}
          layers={[
            {
              zIndex: 10,
              near: 0.0001,
              far: THREE_CAMERA_DEPTH - 0.05,
            },
          ]}
        >
          <ResumeDocumentPage1Three />
        </ResumeThree>
      </DocumentPage>
      <DocumentPage
        documentSize={RESUME_DOCUMENT_SIZE}
        pageNumber={2}
        sx={{
          backgroundColor: theme.palette.background.clear,
        }}
      >
        <ResumeThree pageNumber={2}>
          <ResumeDocumentPage2Three />
        </ResumeThree>
        <Box component="div" sx={{ color: theme.palette.text.secondary }}>
          <ArrowDownwardOutlinedIcon
            fontSize="large"
            color="inherit"
            sx={{
              position: 'absolute',
              left: `calc(${theme.spacing(4)} + 7px)`,
              top: theme.spacing(2),
              opacity: 0.5,
            }}
          />
        </Box>
        <ResumeContainer>
          <Box component="div" sx={{ position: 'relative', paddingTop: 0.5 }}>
            <TransdimensionalRect
              id="resumeDocument_page2_sideContent"
              sx={{
                position: 'absolute',
                left: theme.spacing(-4),
                top: 0,
                width: `calc(${theme.spacing(4)} + 70px)`,
                height: '100%',
              }}
            />
            <ResumeExperienceSectionPage2 />
            {/* <ResumeSection
              sideContent={
                <ResumeSectionLabel>Certifications</ResumeSectionLabel>
              }
            >
              <ResumeList>
                <ResumeListItem label="National Registry of Emergency Medical Technicians">
                  <ResumeList>
                    <ResumeListItem label="Nationally Registered Paramedic" />
                  </ResumeList>
                </ResumeListItem>
                <ResumeListItem label="American Heart Association">
                  <ResumeList>
                    <ResumeListItem label="Basic Life Support (BLS)" />
                    <ResumeListItem label="Advanced Cardiovascular Life Support (ACLS)" />
                    <ResumeListItem label="Pediatric Advanced Life Support (PALS)" />
                  </ResumeList>
                </ResumeListItem>
                <ResumeListItem label="National Association of Emergency Medical Technicians">
                  <ResumeList>
                    <ResumeListItem label="Pre-Hospital Trauma Life Support (PHTLS)" />
                  </ResumeList>
                </ResumeListItem>
                <ResumeListItem label="FEMA Emergency Management Institute">
                  <ResumeList>
                    <ResumeListItem label="IS-00100.b - Introduction to Incident Command System" />
                    <ResumeListItem label="IS-00100.c - Introduction to Incident Command System" />
                    <ResumeListItem label="IS-00200.c - Basic Incident Command System for Initial Response" />
                    <ResumeListItem label="IS-00700.a - National Incident Management System (NIMS), An Introduction" />
                    <ResumeListItem label="IS-00700.b - An Introduction to the National Incident Management System" />
                    <ResumeListItem label="IS-00800.d - National Response Framework, An Introduction" />
                  </ResumeList>
                </ResumeListItem>
              </ResumeList>
            </ResumeSection> */}
          </Box>
        </ResumeContainer>
      </DocumentPage>
    </ThemeProvider>
  );
};
