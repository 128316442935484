import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTransdimensionalOffsetLink } from '../../hooks';
import { TransdimensionalOffsetOptions } from '../../types';

export const TransdimensionalOffset: FC<
  PropsWithChildren<
    TransdimensionalOffsetOptions & {
      display?: 'block' | 'inline-block';
    }
  >
> = (props) => {
  const [elementSymbol, setElementSymbol] = useState(Symbol());
  const elementRef = useRef<HTMLDivElement | null>(null);
  const transformElementRef = useRef<HTMLDivElement>(null);
  const { link, unlink } = useTransdimensionalOffsetLink({
    containerRect: props.containerRect,
    z: props.z,
    handleTransform: (transform) => {
      if (!transformElementRef.current) return;

      transformElementRef.current.style.transform = transform;
    },
  });

  useEffect(() => {
    return () => {
      setTimeout(() => {
        if (elementRef.current !== null) return;

        unlink();
      }, 0);
    };
  }, [unlink]);

  return (
    <div
      ref={(element) => {
        elementRef.current = element;

        link(element);
      }}
      style={{
        display: props.display || 'inline-block',
      }}
    >
      <div ref={transformElementRef}>{props.children}</div>
    </div>
  );
};
