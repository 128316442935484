import { FC } from 'react';
import { Box, useTheme } from '@mui/material';

export const ResumeDivider: FC = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const dividerColor = isDarkMode ? 'rgb(92, 92, 92)' : 'rgb(224, 224, 224)';

  return (
    <Box
      component="hr"
      sx={{
        width: '100%',
        height: 0,
        border: '1px solid',
        borderColor: dividerColor,
        marginTop: 1,
        marginBottom: 1,
      }}
    />
  );
};
