import { Theme, useTheme } from '@mui/material';
import { LIGHT_THEME, DARK_THEME } from '../../constants';

export const useInvertedTheme = (theme?: Theme) => {
  const hookTheme = useTheme();
  const parentTheme = theme ?? hookTheme;
  let invertedTheme = {
    ...parentTheme,
  };

  switch (parentTheme.palette.mode) {
    case 'dark':
      invertedTheme = {
        ...LIGHT_THEME,
        ...parentTheme,
        palette: {
          ...parentTheme.palette,
          ...LIGHT_THEME.palette,
        },
      };

      break;
    case 'light':
      invertedTheme = {
        ...DARK_THEME,
        ...parentTheme,
        palette: {
          ...parentTheme.palette,
          ...DARK_THEME.palette,
        },
      };

      break;
  }

  return invertedTheme;
};
