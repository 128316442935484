import { FC, useState } from 'react';
import 'katex/dist/katex.min.css';
import { Link, Tab, Tabs } from '@mui/material';
import { PresentationPage as PresentationPageType } from '../../types';
import { PresentationMarkdown } from '.';

export const PresentationPage: FC<{
  presentationPage?: PresentationPageType;
}> = ({ presentationPage }) => {
  const [tabIndex, setTabIndex] = useState(0);

  if (!presentationPage) return null;

  return (
    <>
      {presentationPage.tabs && (
        <>
          <Tabs
            value={tabIndex}
            onChange={() => console.log('changed tab')}
            sx={{ marginBottom: 1 }}
          >
            {presentationPage.tabs.map((tab, tabIndex) => (
              <Tab
                {...(tab.href && {
                  component: Link,
                  href: tab.href,
                  target: '_blank',
                })}
                key={tabIndex}
                label={tab.label}
                icon={tab.icon}
                iconPosition={tab.iconPosition ?? 'start'}
                sx={{
                  minHeight: 0,
                }}
              />
            ))}
          </Tabs>
          <PresentationPage
            presentationPage={presentationPage.tabs[tabIndex].page}
          />
        </>
      )}
      {presentationPage.markdown && (
        <PresentationMarkdown
          markdown={presentationPage.markdown}
        ></PresentationMarkdown>
      )}
    </>
  );
};
