import { FC, useRef } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Vector3 } from 'three';
import { LayerMaterial, Depth, Gradient, Fresnel } from 'lamina/vanilla';
import {
  ThreeText,
  ThreeTransdimensionalRect,
  TransdimensionalRect,
} from '../..';
import { toColor } from '../../../utilities';
import { ResumeContainer } from '.';

const HERO_TEXT_DEPTH = 0.1;
const HERO_TEXT_DEPTH_RATIO = 0.4;
const HERO_TEXT_SCALE = 0.9;
const HERO_TEXT_OFFSET_Y = 0.0075;

export const ResumeHeroThree: FC = () => {
  const theme = useTheme();
  const brandRedMain = toColor(theme.palette.brandRed.main);
  const brandOrangeMain = toColor(theme.palette.brandOrange.main);
  const brandYellowMain = toColor(
    theme.palette.brandYellow.main
  ).convertLinearToSRGB();

  const gradientMaterialRef = useRef(
    new LayerMaterial({
      lighting: 'standard',
      transmission: 1,
      layers: [
        new Depth({
          colorA: brandRedMain,
          colorB: 'black',
          alpha: 1,
          mode: 'normal',
          mapping: 'vector',
          near: 0.4,
          far: 0.48,
          origin: new Vector3(0, 0.3, 0.2),
        }),
        new Depth({
          colorA: '#000000',
          colorB: brandOrangeMain,
          alpha: 1,
          mode: 'add',
          mapping: 'vector',
          near: 0.4,
          far: 0.48,
          origin: new Vector3(0, 0.3, 0.2),
        }),
        new Depth({
          colorA: '#00FF00',
          colorB: '#000000',
          alpha: 1,
          mode: 'add',
          mapping: 'vector',
          near: 0,
          far: 0.25,
          origin: new Vector3(0.6, 0, -0.1),
        }),
        new Depth({
          colorA: '#F00000',
          colorB: '#FFFFFF',
          alpha: 1,
          mode: 'multiply',
          mapping: 'vector',
          near: 0.2,
          far: 0.3,
          origin: new Vector3(-0.1, 0.05, 0),
        }),
        new Fresnel({
          color: '#00FF00',
          alpha: 1,
          intensity: 0.125,
          power: 1.5,
          bias: 0.05,
          mode: 'add',
        }),
      ],
    })
  );

  return (
    <ThreeTransdimensionalRect id="resumeDocument_heroText">
      <ThreeText
        fontId="OpenSans_ExtraBold"
        text="Sam"
        material={gradientMaterialRef.current}
        position={[
          -0.505,
          -0.192 + (0.365 * (1 - HERO_TEXT_SCALE)) / 2 + HERO_TEXT_OFFSET_Y,
          -1 * HERO_TEXT_DEPTH_RATIO * HERO_TEXT_DEPTH,
        ]}
        scale={[0.365, 0.365 * HERO_TEXT_SCALE, HERO_TEXT_DEPTH / 2]}
        log
      />
    </ThreeTransdimensionalRect>
  );
};

export const ResumeHero: FC = () => {
  const theme = useTheme();

  return (
    <ResumeContainer>
      <Typography variant="h1" fontSize="4rem" sx={{ position: 'relative' }}>
        <TransdimensionalRect
          id="resumeDocument_heroText"
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            aspectRatio: '1 / 1',
          }}
        />
        <Box
          component="span"
          sx={{
            opacity: 0,
          }}
        >
          Sam
        </Box>
        uel Goodell
        <Box
          component="span"
          sx={{
            marginLeft: 2,
            fontSize: '1.5rem',
            color: theme.palette.text.disabled,
          }}
        >
          BSc, NREMT-P
        </Box>
      </Typography>
    </ResumeContainer>
  );
};
