import { takeEvery, call, put } from 'redux-saga/effects';
import { Font } from 'three-stdlib';

import { ThreeFontsService } from '../services';
import * as threeFontsActions from '../actions/threeFontsActions';

function* loadFont(
  action: ReturnType<typeof threeFontsActions.requestThreeFont>
) {
  const { id, url } = action.payload;
  const font: Font = yield call(ThreeFontsService.fetch, url);

  yield put(threeFontsActions.setThreeFont({ id, font }));
}

export function* threeFontsSaga() {
  yield takeEvery(threeFontsActions.requestThreeFont.type, loadFont);
}
