// Redux
import { createSelector } from '@reduxjs/toolkit';

// Types
import { RootState } from '../store';

export const selectThreeFonts = (state: RootState) => state.threeFonts;

const createThreeFontSelector = (fontId: string) =>
  createSelector(
    selectThreeFonts,
    (threeFontsState) => threeFontsState.threeFontsById[fontId]
  );

const threeFontSelectorsById = new Map<
  string /* font ID */,
  ReturnType<typeof createThreeFontSelector>
>();

export const getOrCreateThreeFontSelector = (fontId: string) => {
  let selector = threeFontSelectorsById.get(fontId);

  if (!selector) {
    selector = createThreeFontSelector(fontId);

    threeFontSelectorsById.set(fontId, selector);
  }

  return selector;
};
