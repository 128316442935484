import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GLTF } from 'three-stdlib';

export type ThreeModelsState = {
  threeModelsById: {
    [modelId: string]: GLTF;
  };
};

const INITIAL_STATE: ThreeModelsState = {
  threeModelsById: {},
};

export const threeModelsSlice = createSlice({
  name: 'threeModels',
  initialState: INITIAL_STATE,
  reducers: {
    setThreeModel: (
      state,
      action: PayloadAction<{
        id: string;
        model: GLTF;
      }>
    ) => {
      const { id, model } = action.payload;

      state.threeModelsById = {
        ...state.threeModelsById,
        [id]: model as any,
      };
    },
  },
});
