import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import simplePlantUML from '@akebifiky/remark-simple-plantuml';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { Typography } from '@mui/material';
import { InlineLink } from '../inline-link';

export const PresentationMarkdown: FC<{
  markdown: string;
}> = ({ markdown }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[
        [
          simplePlantUML as any,
          { baseUrl: 'https://www.plantuml.com/plantuml/svg' },
        ],

        remarkMath,
      ]}
      rehypePlugins={[
        [
          rehypeKatex,
          {
            strict: (errorCode: string, errorMsg: any, token: string) => {
              console.log('strict callback', errorCode);
              switch (errorCode) {
                case 'htmlExtension':
                  console.log('html extension, ignore');
                  // return false;
                  return 'ignore';
                default:
                  return 'error';
              }
            },
            trust: true,
          },
        ],
      ]}
      components={{
        ...(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const).reduce(
          (components, variant) => ({
            ...components,
            [variant]: ({ node, ...props }: any) => {
              return <Typography variant={variant} {...props} />;
            },
          }),
          {}
        ),
        p: ({ node, ...props }) => {
          return <Typography variant="body1" {...props} />;
        },
        a: ({ node, ...props }) => {
          return <InlineLink to={props.href ?? ''} {...props} />;
        },
        img: ({ node, ...props }) => {
          return <img {...props} style={{ maxWidth: '100%' }} />;
        },
      }}
    >
      {markdown}
    </ReactMarkdown>
  );
};
