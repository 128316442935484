import { FC } from 'react';
import { useTheme } from '@mui/material';
import {
  Badge,
  Chip,
  ChipList,
  ChipThree,
  Icon,
  TransdimensionalRect,
} from '../..';
import { useInvertedTheme } from '../../../hooks';
import { SERVICE_ITEMS_BY_ID } from '../../../constants';
import { ResumeSection, ResumeSectionLabel, ResumeDivider } from '.';

const RESUME_SERVICE_ITEMS = [
  SERVICE_ITEMS_BY_ID['typescript'],
  SERVICE_ITEMS_BY_ID['javascript'],
  SERVICE_ITEMS_BY_ID['react'],
  SERVICE_ITEMS_BY_ID['node'],
  SERVICE_ITEMS_BY_ID['graphql'],
  SERVICE_ITEMS_BY_ID['amazon_web_services'],
  SERVICE_ITEMS_BY_ID['docker'],
  SERVICE_ITEMS_BY_ID['terraform'],
  SERVICE_ITEMS_BY_ID['ruby_on_rails'],
];

const getServiceItemRectId = (serviceItemId: string) =>
  `resumeDocument_serviceItem_${serviceItemId}`;

export const ResumeSkillsSectionThree: FC = () => {
  return (
    <>
      {RESUME_SERVICE_ITEMS.map((itemProps) => {
        return (
          <ChipThree id={getServiceItemRectId(itemProps.id)} depth={0.15} />
        );
      })}
    </>
  );
};

export const ResumeSkillsSection: FC = () => {
  const theme = useTheme();
  const invertedTheme = useInvertedTheme();

  return (
    <ResumeSection
      sideContent={<ResumeSectionLabel>Skills</ResumeSectionLabel>}
    >
      <ChipList
        sx={{
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
      >
        {RESUME_SERVICE_ITEMS.map((itemProps) => {
          return (
            <TransdimensionalRect
              key={itemProps.id}
              id={getServiceItemRectId(itemProps.id)}
              display="inline-block"
            >
              <Chip
                key={itemProps.id}
                background={theme.palette.text.primary}
                startAdornment={
                  itemProps.iconUrl && (
                    <Icon
                      url={itemProps.iconUrl}
                      color={invertedTheme.palette.text.primary}
                    />
                  )
                }
              >
                {itemProps.label}
                {itemProps.badge && (
                  <Badge
                    color={itemProps.badgeColor}
                    background={itemProps.badgeBackground}
                    spacingLeft
                  >
                    {itemProps.badge}
                  </Badge>
                )}
              </Chip>
            </TransdimensionalRect>
          );
        })}
      </ChipList>
      <ResumeDivider />
    </ResumeSection>
  );
};
