import { FC, Fragment } from 'react';
import { Color } from 'three';
import { REFERENCE_ASSETS_3D } from '../../../../constants';
import { TransdimensionalUnitConversionServiceContext } from '../../../../contexts';
import { standardHighlightMaterial } from '../../../../materials';
import { useSpacing, useWindowSize } from '../../../../hooks';
import {
  ChipThree,
  Three,
  ThreeQuad,
  ThreeScrollOffsetContainer,
  ThreeTransdimensionalRect,
  ThreeTransdimensionalGrid,
  ThreeTransdimensionalBorder,
  ThreeModel,
  ThreeLighting,
  ThreeInterceptor,
} from '../../..';

const CHIP_THREE_PROPS = {
  variant: 'solid' as const,
  depth: 0.1,
};

export const Reference3DAssetsPageThree: FC = () => {
  const gridBorderWidth = useSpacing(2);

  return (
    <Three
      id="assets_page_three"
      inset
      fogFar={50}
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <ThreeScrollOffsetContainer>
        <ThreeLighting />
        <TransdimensionalUnitConversionServiceContext.Consumer>
          {(transdimensionalUnitConversionService) => {
            if (!transdimensionalUnitConversionService) return null;

            const sceneWidth =
              transdimensionalUnitConversionService.getVisibleWidthAtDepth(0);
            const sceneHeight =
              transdimensionalUnitConversionService.getVisibleHeightAtDepth(0);
            const sceneDepth = 0.5;

            return (
              <>
                <ThreeTransdimensionalBorder
                  id="assetsPage_primitiveAssetsGrid"
                  all={gridBorderWidth}
                  top={64}
                />
                <ThreeTransdimensionalGrid id="assetsPage_primitiveAssetsGrid" />
                {REFERENCE_ASSETS_3D.map((referenceAsset3d) => (
                  <Fragment key={referenceAsset3d.id}>
                    <ChipThree
                      {...CHIP_THREE_PROPS}
                      id={`${referenceAsset3d.id}-chip`}
                    />
                    <ThreeTransdimensionalBorder
                      id={referenceAsset3d.id}
                      all={gridBorderWidth}
                    />
                    <ThreeTransdimensionalRect id={referenceAsset3d.id}>
                      {/* Ceiling */}
                      <ThreeQuad
                        position={[0, 1 / 2, -1 / 2]}
                        scale={[1, 1, 1]}
                        rotation={[Math.PI / 2, 0, 0]}
                      />

                      {/* Back wall */}
                      <ThreeQuad
                        position={[0, 0, -1]}
                        scale={[1, 1, 1]}
                        rotation={[0, 0, 0]}
                      />

                      {/* Floor */}
                      <ThreeQuad
                        position={[0, -1 / 2, -1 / 2]}
                        scale={[1, 1, 1]}
                        rotation={[Math.PI / -2, 0, 0]}
                      />

                      {/* Right wall */}
                      <ThreeQuad
                        position={[1 / 2, 0, -1 / 2]}
                        scale={[1, 1, 1]}
                        rotation={[0, Math.PI / -2, 0]}
                      />

                      {/* Left wall */}
                      <ThreeQuad
                        position={[-1 / 2, 0, -1 / 2]}
                        scale={[1, 1, 1]}
                        rotation={[0, Math.PI / 2, 0]}
                      />

                      {/* <ThreeInterceptor>
                        {(parent) => {
                          const bo
                          return <>
                            <ThreeQuad
                              position={[0, 1, 0]}
                              scale={[1, 1, 1]}
                              rotation={[0, 0, 0]}
                            />
                          </>
                        }}
                      </ThreeInterceptor> */}

                      <ThreeModel
                        id={referenceAsset3d.id}
                        scale={[0.25, 0.25, 0.25]}
                        material={standardHighlightMaterial}
                      />
                    </ThreeTransdimensionalRect>
                  </Fragment>
                ))}
              </>
            );
          }}
        </TransdimensionalUnitConversionServiceContext.Consumer>
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
