import { FC, PropsWithChildren } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  LAYOUT_ANCHOR_ID_SERVICES_TOP,
  LAYOUT_ANCHOR_ID_SERVICES_BOTTOM,
  LAYOUT_ANCHOR_ID_SERVICES_SIDE_LEFT,
  LAYOUT_ANCHOR_ID_SERVICES_SIDE_RIGHT,
  LAYOUT_ANCHOR_ID_SERVICES_CONTENT_LEFT,
  LAYOUT_ANCHOR_ID_SERVICES_CONTENT_RIGHT,
  LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_HEADING_LEFT,
  LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_HEADING_RIGHT,
  LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_BODY_TOP,
  LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_BODY_BOTTOM,
  LAYOUT_ANCHOR_ID_SERVICES_DESIGN_HEADING_LEFT,
  LAYOUT_ANCHOR_ID_SERVICES_DESIGN_HEADING_RIGHT,
  LAYOUT_ANCHOR_ID_SERVICES_DESIGN_BODY_TOP,
  LAYOUT_ANCHOR_ID_SERVICES_DESIGN_BODY_BOTTOM,
  LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_HEADING_LEFT,
  LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_HEADING_RIGHT,
  LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_BODY_BOTTOM,
  LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_BODY_TOP,
  SERVICES_DEVELOP_SERVICE_ITEM_SECTIONS,
  SERVICES_DESIGN_SERVICE_ITEM_SECTIONS,
  SERVICES_DEPLOY_SERVICE_ITEM_SECTIONS,
} from '../../../../constants';
import { ServiceItemSectionProps } from '../../../../types';
import { useLayoutPoint, useWindowSize } from '../../../../hooks';
import {
  roundScalar,
  toLayoutPointX,
  toLayoutPointY,
} from '../../../../utilities';
import {
  Badge,
  Chip,
  ChipList,
  Heading,
  Icon,
  LayoutAnchor,
  LinearProgressArrow,
  RadialProgressArrow,
  StaticEffectMask,
  TransdimensionalRect,
} from '../../..';
import {
  MainPageServicesGridDevelopThree,
  MainPageServicesGridDesignThree,
  MainPageServicesGridDeployThree,
} from '.';

const ServicesDevelopRadialProgressArrow: FC = () => {
  const servicesTopY =
    useLayoutPoint(toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_TOP)) || 0;
  const sideLeftX = useLayoutPoint(
    toLayoutPointX(LAYOUT_ANCHOR_ID_SERVICES_SIDE_LEFT)
  );
  const developHeadingLeftScreenX =
    useLayoutPoint(
      toLayoutPointX(LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_HEADING_LEFT)
    ) || 0;
  const developHeadingScreenY =
    ((useLayoutPoint(
      toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_HEADING_LEFT)
    ) || 0) +
      (useLayoutPoint(
        toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_HEADING_RIGHT)
      ) || 0)) /
    2;

  if (sideLeftX === null) {
    return null;
  }

  const fromX = sideLeftX;
  const toX = developHeadingLeftScreenX - 12;
  const width = toX - fromX;
  const height = developHeadingScreenY - servicesTopY - 100;

  return (
    <div className="ServicesGrid-progressArrowContainer">
      <RadialProgressArrow
        id="services_develop_arrow"
        fromLayoutPoint={toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_TOP)}
        toLayoutPoint={toLayoutPointY(
          LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_HEADING_LEFT
        )}
        showTopBar={false}
        width={width}
        height={height}
        className="ServicesGrid-arrowSideLeft"
      />
    </div>
  );
};

const ServicesDesignRadialProgressArrow: FC = () => {
  const servicesTopY =
    useLayoutPoint(toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_TOP)) || 0;
  const sideRightX = useLayoutPoint(
    toLayoutPointX(LAYOUT_ANCHOR_ID_SERVICES_SIDE_RIGHT)
  );
  const developHeadingRightScreenX =
    useLayoutPoint(
      toLayoutPointX(LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_HEADING_RIGHT)
    ) || 0;
  const developHeadingScreenY =
    ((useLayoutPoint(
      toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_HEADING_LEFT)
    ) || 0) +
      (useLayoutPoint(
        toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_HEADING_RIGHT)
      ) || 0)) /
    2;
  const designHeadingRightScreenX =
    useLayoutPoint(
      toLayoutPointX(LAYOUT_ANCHOR_ID_SERVICES_DESIGN_HEADING_RIGHT)
    ) || 0;
  const designHeadingScreenY =
    ((useLayoutPoint(
      toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_DESIGN_HEADING_LEFT)
    ) || 0) +
      (useLayoutPoint(
        toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_DESIGN_HEADING_RIGHT)
      ) || 0)) /
    2;

  if (sideRightX === null) {
    return null;
  }

  const fromX = Math.min(developHeadingRightScreenX, designHeadingRightScreenX);
  const width = sideRightX - fromX - 12;
  const height = designHeadingScreenY - developHeadingScreenY;
  const topBarWidth = sideRightX - developHeadingRightScreenX - 12;
  const bottomBarWidth = sideRightX - designHeadingRightScreenX - 12;
  const top = developHeadingScreenY - servicesTopY - 7;

  return (
    <div
      className="ServicesGrid-progressArrowContainer ServicesGrid-progressArrowSideRightContainer"
      style={{
        top: `${top}px`,
      }}
    >
      <RadialProgressArrow
        id="services_design_arrow"
        showTopBar
        topBarWidth={topBarWidth}
        bottomBarWidth={bottomBarWidth}
        width={width}
        height={height}
        className="ServicesGrid-arrowSideRight"
      />
    </div>
  );
};

const ServicesDeployRadialProgressArrow: FC = () => {
  const servicesTopY =
    useLayoutPoint(toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_TOP)) || 0;
  const sideLeftX = useLayoutPoint(
    toLayoutPointX(LAYOUT_ANCHOR_ID_SERVICES_SIDE_LEFT)
  );
  const designHeadingLeftScreenX =
    useLayoutPoint(
      toLayoutPointX(LAYOUT_ANCHOR_ID_SERVICES_DESIGN_HEADING_LEFT)
    ) || 0;
  const designHeadingScreenY =
    ((useLayoutPoint(
      toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_DESIGN_HEADING_LEFT)
    ) || 0) +
      (useLayoutPoint(
        toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_DESIGN_HEADING_RIGHT)
      ) || 0)) /
    2;
  const deployHeadingLeftScreenX =
    useLayoutPoint(
      toLayoutPointX(LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_HEADING_LEFT)
    ) || 0;
  const deployHeadingcreenY =
    ((useLayoutPoint(
      toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_HEADING_LEFT)
    ) || 0) +
      (useLayoutPoint(
        toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_HEADING_RIGHT)
      ) || 0)) /
    2;

  if (sideLeftX === null) {
    return null;
  }

  const fromX = Math.max(designHeadingLeftScreenX, deployHeadingLeftScreenX);
  const width = fromX - sideLeftX - 12;
  const height = deployHeadingcreenY - designHeadingScreenY;
  const topBarWidth = designHeadingLeftScreenX - sideLeftX - 12;
  const bottomBarWidth = deployHeadingLeftScreenX - sideLeftX - 12;
  const top = designHeadingScreenY - servicesTopY - 7;

  return (
    <div
      className="ServicesGrid-progressArrowContainer"
      style={{
        top: `${top}px`,
      }}
    >
      <RadialProgressArrow
        id="services_deploy_arrow"
        showTopBar
        topBarWidth={topBarWidth}
        bottomBarWidth={bottomBarWidth}
        width={width}
        height={height}
        className="ServicesGrid-progressArrow"
      />
    </div>
  );
};

const ServicesLinearProgressArrow: FC = () => {
  const windowSize = useWindowSize();
  const screenRightX = windowSize.x;
  const servicesTopY =
    useLayoutPoint(toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_TOP)) || 0;
  const sideRightX = useLayoutPoint(
    toLayoutPointX(LAYOUT_ANCHOR_ID_SERVICES_SIDE_RIGHT)
  );
  const deployHeadingRightScreenX =
    useLayoutPoint(
      toLayoutPointX(LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_HEADING_RIGHT)
    ) || 0;
  const deployHeadingcreenY =
    ((useLayoutPoint(
      toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_HEADING_LEFT)
    ) || 0) +
      (useLayoutPoint(
        toLayoutPointY(LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_HEADING_RIGHT)
      ) || 0)) /
    2;

  if (sideRightX === null) {
    return null;
  }

  const fromX = deployHeadingRightScreenX;
  const width = screenRightX - fromX - 2 * 12;
  const top = deployHeadingcreenY - servicesTopY - 7 - 12;
  const left = `calc(100% - ${roundScalar(
    sideRightX - deployHeadingRightScreenX - 12
  )}px)`;

  return (
    <Box
      component="div"
      sx={{
        position: 'absolute',
        top,
        left,
      }}
    >
      <LinearProgressArrow
        id="services_linear_arrow"
        width={width}
        className="ServicesGrid-arrowSideRight"
        toLayoutPoint={toLayoutPointY(
          LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_BODY_BOTTOM
        )}
      />
    </Box>
  );
};

const renderServiceItemSections = (itemSections: ServiceItemSectionProps[]) => {
  const theme = useTheme();
  const textColor = theme.palette.getContrastText(
    theme.palette.background.chip
  );

  return (
    <Stack direction="column" spacing={2}>
      {itemSections.map((itemSectionProps) => {
        return (
          <ChipList key={itemSectionProps.id} label={itemSectionProps.label}>
            {itemSectionProps.items.map((itemProps) => {
              return (
                <TransdimensionalRect
                  key={itemProps.id}
                  id={itemProps.id}
                  display="inline-block"
                >
                  <Chip
                    key={itemProps.id}
                    startAdornment={
                      itemProps.iconUrl && (
                        <Icon url={itemProps.iconUrl} color={textColor} />
                      )
                    }
                  >
                    {itemProps.label}
                    {itemProps.badge && (
                      <Badge
                        color={itemProps.badgeColor}
                        background={itemProps.badgeBackground}
                        spacingLeft
                      >
                        {itemProps.badge}
                      </Badge>
                    )}
                  </Chip>
                </TransdimensionalRect>
              );
            })}
          </ChipList>
        );
      })}
    </Stack>
  );
};

const useServicesGridSideWidth = () => {
  const theme = useTheme();

  if (useMediaQuery(theme.breakpoints.down('md'))) {
    return '48px';
  } else {
    return '100px';
  }
};

const ServicesGridContentBody: FC<
  PropsWithChildren<{
    align: 'left' | 'right' | 'center';
  }>
> = (props) => {
  const theme = useTheme();
  const sideWidth = useServicesGridSideWidth();

  return (
    <Box
      component="div"
      className="ServicesGrid-contentSection-body"
      sx={{
        padding: theme.spacing(0, 2),
        [theme.breakpoints.down('sm')]: {
          width: `calc(100% + ${sideWidth})`,
          ...(props.align === 'left' && {
            marginLeft: `calc(-1 * ${sideWidth})`,
            paddingLeft: 0,
            textAlign: 'left',
          }),
          ...(props.align === 'right' && {
            marginRight: `calc(-1 * ${sideWidth})`,
            paddingRight: 0,
            textAlign: 'right',
          }),
        },
      }}
    >
      {props.children}
    </Box>
  );
};

export const MainPageServicesGrid: FC = () => {
  const theme = useTheme();
  const sideWidth = useServicesGridSideWidth();
  const headingSx = {
    fontSize: '3.8rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.5rem',
    },
  };

  return (
    <Box
      component="div"
      className="ServicesGrid-container"
      sx={{
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
          width: `calc((100% - (2 * ${theme.spacing(3)})))`,
          margin: theme.spacing(0, 3),
        },
        [theme.breakpoints.down('sm')]: {
          width: `calc((100% - (2 * ${theme.spacing(2)})))`,
          margin: theme.spacing(0, 2),
        },
      }}
    >
      <LayoutAnchor
        id={LAYOUT_ANCHOR_ID_SERVICES_TOP}
        y
        style={{ position: 'absolute', top: 0 }}
      />
      <LayoutAnchor
        id={LAYOUT_ANCHOR_ID_SERVICES_BOTTOM}
        y
        style={{ position: 'absolute', bottom: 0 }}
      />
      <div
        style={{
          position: 'absolute',
          left: '0px',
          top: '352px',
          width: '100%',
          height: 'calc(100% - 352px)',
        }}
      />
      <div className="ServicesGrid-gutter ServicesGrid-gutter-left" />
      <div className="ServicesGrid">
        <Box
          component="div"
          className="ServicesGrid-side ServicesGrid-side-left"
          sx={{
            width: sideWidth,
          }}
        >
          <LayoutAnchor
            id={LAYOUT_ANCHOR_ID_SERVICES_SIDE_LEFT}
            style={{ position: 'absolute', left: 0 }}
            x
          />
          <Heading variant="h2" id="what-i-do">
            What I Do
          </Heading>
          <ServicesDevelopRadialProgressArrow />
          <ServicesDeployRadialProgressArrow />
        </Box>
        <div className="ServicesGrid-content">
          <LayoutAnchor
            id={LAYOUT_ANCHOR_ID_SERVICES_CONTENT_LEFT}
            style={{ position: 'absolute', left: 0 }}
            x
          />
          <div className="ServicesGrid-contentSection AlignmentContext AlignmentContext-alignCenter">
            <StaticEffectMask>
              <Typography variant="h3" sx={headingSx}>
                <LayoutAnchor
                  id={LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_HEADING_LEFT}
                  style={{ display: 'inline-block', verticalAlign: 'top' }}
                  x
                  y
                />
                Develop
                <LayoutAnchor
                  id={LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_HEADING_RIGHT}
                  style={{ display: 'inline-block', verticalAlign: 'bottom' }}
                  x
                  y
                />
              </Typography>
            </StaticEffectMask>
            <ServicesGridContentBody align="left">
              <LayoutAnchor id={LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_BODY_TOP} y />
              <br />
              <div className="ServicesGrid-threeContainer ServicesGrid-threeContainer-alignRight">
                <MainPageServicesGridDevelopThree />
              </div>
              {renderServiceItemSections(
                SERVICES_DEVELOP_SERVICE_ITEM_SECTIONS
              )}
              <br />
              <LayoutAnchor
                id={LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_BODY_BOTTOM}
                y
              />
            </ServicesGridContentBody>
          </div>
          <div className="ServicesGrid-contentSection AlignmentContext AlignmentContext-alignCenter">
            <StaticEffectMask>
              <Typography variant="h3" sx={headingSx}>
                <LayoutAnchor
                  id={LAYOUT_ANCHOR_ID_SERVICES_DESIGN_HEADING_LEFT}
                  style={{ display: 'inline-block', verticalAlign: 'top' }}
                  x
                  y
                />
                Design
                <LayoutAnchor
                  id={LAYOUT_ANCHOR_ID_SERVICES_DESIGN_HEADING_RIGHT}
                  style={{ display: 'inline-block', verticalAlign: 'bottom' }}
                  x
                  y
                />
              </Typography>
            </StaticEffectMask>
            <ServicesGridContentBody align="right">
              <LayoutAnchor id={LAYOUT_ANCHOR_ID_SERVICES_DESIGN_BODY_TOP} y />
              <br />
              <div className="ServicesGrid-threeContainer ServicesGrid-threeContainer-alignLeft">
                <MainPageServicesGridDesignThree />
              </div>
              {renderServiceItemSections(SERVICES_DESIGN_SERVICE_ITEM_SECTIONS)}
              <br />
              <LayoutAnchor
                id={LAYOUT_ANCHOR_ID_SERVICES_DESIGN_BODY_BOTTOM}
                y
              />
            </ServicesGridContentBody>
          </div>
          <div className="ServicesGrid-contentSection AlignmentContext AlignmentContext-alignCenter">
            <StaticEffectMask>
              <Typography variant="h3" sx={headingSx}>
                <LayoutAnchor
                  id={LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_HEADING_LEFT}
                  style={{ display: 'inline-block', verticalAlign: 'top' }}
                  x
                  y
                />
                Deploy
                <LayoutAnchor
                  id={LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_HEADING_RIGHT}
                  style={{ display: 'inline-block', verticalAlign: 'bottom' }}
                  x
                  y
                />
              </Typography>
            </StaticEffectMask>
            <ServicesGridContentBody align="center">
              <LayoutAnchor id={LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_BODY_TOP} y />
              <br />
              <div className="ServicesGrid-threeContainer ServicesGrid-threeContainer-fullWidth">
                <MainPageServicesGridDeployThree />
              </div>
              {renderServiceItemSections(SERVICES_DEPLOY_SERVICE_ITEM_SECTIONS)}
              <br />
              <LayoutAnchor
                id={LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_BODY_BOTTOM}
                y
              />
            </ServicesGridContentBody>
          </div>
          <LayoutAnchor
            id={LAYOUT_ANCHOR_ID_SERVICES_CONTENT_RIGHT}
            style={{ position: 'absolute', right: 0 }}
            x
          />
        </div>
        <Box
          component="div"
          className="ServicesGrid-side ServicesGrid-side-right"
          sx={{
            width: sideWidth,
          }}
        >
          <LayoutAnchor
            id={LAYOUT_ANCHOR_ID_SERVICES_SIDE_RIGHT}
            style={{ position: 'absolute', right: 0 }}
            x
          />
          <ServicesDesignRadialProgressArrow />
          <ServicesLinearProgressArrow />
        </Box>
      </div>
      <div className="ServicesGrid-gutter ServicesGrid-gutter-right" />
    </Box>
  );
};
