import { lighten, darken, useTheme } from '@mui/material';

export const enhance = (color: string, amount: number) => {
  const theme = useTheme();

  switch (theme.palette.mode) {
    case 'dark':
      if (amount > 0) {
        return lighten(color, amount);
      } else if (amount < 0) {
        return darken(color, amount * -1);
      } else {
        return color;
      }
    case 'light':
      if (amount > 0) {
        return darken(color, amount);
      } else if (amount < 0) {
        return lighten(color, amount * -1);
      } else {
        return color;
      }
    default:
      return color;
  }
};
