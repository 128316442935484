import {
  FC,
  PropsWithChildren,
  ReactNode,
  Children,
  cloneElement,
  isValidElement,
} from 'react';
import { Box, SxProps, Theme, useTheme } from '@mui/material';
import classNames from 'classnames';

const CHIP_LIST_ITEM_SPACING = 1.5;

export const ChipList: FC<
  PropsWithChildren<{
    className?: string;
    align?: 'left' | 'center' | 'right';
    label?: ReactNode;
    sx?: SxProps<Theme>;
  }>
> = ({ className, label, sx, children, ...props }) => {
  const theme = useTheme();
  const align = props.align ?? 'left';

  return (
    <Box
      component="div"
      className={classNames('ChipList', className)}
      sx={[
        {
          position: 'relative',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {label && (
        <Box
          component="label"
          className="ChipList-label"
          sx={{
            display: 'block',
            marginBottom: 1,
            fontWeight: '500',
          }}
        >
          {label}
        </Box>
      )}
      <Box
        component="ul"
        className="ChipList-list"
        sx={{
          listStyle: 'none',
          paddingLeft: 0,
          marginBlockStart: 0,
          marginBlockEnd: 0,
          marginBottom: -CHIP_LIST_ITEM_SPACING,
          '> *': {
            marginBottom: CHIP_LIST_ITEM_SPACING,
            ...(align === 'left' && {
              marginLeft: 0,
              marginRight: CHIP_LIST_ITEM_SPACING,
            }),
            ...(align === 'center' && {
              marginLeft: CHIP_LIST_ITEM_SPACING / 2,
              marginRight: CHIP_LIST_ITEM_SPACING / 2,
            }),
            ...(align === 'center' && {
              marginLeft: CHIP_LIST_ITEM_SPACING,
              marginRight: 0,
            }),
          },
        }}
      >
        {Children.map(children, (child) => {
          if (!isValidElement(child)) {
            return child;
          }

          return cloneElement(child, { component: 'li' } as any);
        })}
      </Box>
    </Box>
  );
};
