import { FC, forwardRef, PropsWithChildren, useEffect, useRef } from 'react';
import { Group, Mesh } from 'three';
import { useSelector } from 'react-redux';
import { getOrCreateThreeFontSelector } from '../../selectors';
import { ObjectProps, MeshProps } from '../../types';
import { useMaterial } from '../../hooks';
import { TextGeometry } from 'three-stdlib';

export interface ThreeTextProps extends ObjectProps, MeshProps {
  fontId: string;
  text: string;
  log?: boolean;
}

export const ThreeText = forwardRef<Mesh, PropsWithChildren<ThreeTextProps>>(
  (
    { fontId, text, log, scale, position, rotation, ...props },
    forwardedRef
  ) => {
    const groupRef = useRef<Group>();
    const meshRef = useRef<Mesh>();
    const standardMaterial = useMaterial();
    const material = props.material ?? standardMaterial;
    const threeFont = useSelector(getOrCreateThreeFontSelector(fontId));

    useEffect(() => {
      (async () => {
        const group = groupRef.current;

        if (!group) {
          if (log) console.log('group ref not set');

          return;
        }

        if (!threeFont) {
          if (log) console.log('font not loaded');

          return;
        }

        if (log) console.log('creating text geometry');

        const geometry = new TextGeometry(text, {
          font: threeFont,
          size: 1,
          height: 1,
          letterSpacing: -0.01,
        });

        if (meshRef.current) {
          group.remove(meshRef.current);
        }

        const mesh = (meshRef.current = new Mesh(geometry, material));

        group.add(mesh);

        if (forwardedRef instanceof Function) {
          forwardedRef(mesh);
        }
      })();
    }, [threeFont, text]);

    return (
      <group
        ref={groupRef as any}
        scale={scale}
        position={position}
        rotation={rotation}
      />
    );
  }
);
