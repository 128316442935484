import { FC, PropsWithChildren } from 'react';
import { Box, useTheme } from '@mui/material';

export const ResumeContainer: FC<PropsWithChildren<{}>> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      component="div"
      sx={{ position: 'relative', padding: theme.spacing(0, 4) }}
    >
      {children}
    </Box>
  );
};
