import './@mui/material/styles';
import 'leaflet/dist/leaflet.css';
import './history';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { ThreeModelsService } from './services';
import { applyStandardMaterial } from './utilities';
import { Application } from './components';

ThreeModelsService.defineTransformation(
  'standardizeMaterials',
  applyStandardMaterial
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>
);
