import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Box, ThemeProvider, Typography, useTheme } from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { LIGHT_THEME } from '../../../constants';
import { enhance } from '../../../utilities';
import { useInvertedTheme, useMaterial } from '../../../hooks';
import { getOrCreateTransdimensionalRectSelector } from '../../../selectors';
import {
  Chip,
  ChipThree,
  ThreeBox,
  ThreeModel,
  ThreeTransdimensionalRect,
  TransdimensionalOffset,
  TransdimensionalRect,
} from '../..';
import { ResumeSection } from '.';

export const ResumeMeetThree: FC = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const contactMaterial = useMaterial(
    (theme) => theme.palette.brandYellow.main,
    { convertSRGBToLinear: true }
  );
  const basicMaterial2 = useMaterial(
    enhance(theme.palette.background.object, isDarkMode ? 0.45 : 0.0125),
    {
      convertSRGBToLinear: true,
    }
  );
  const basicMaterial3 = useMaterial(
    enhance(theme.palette.background.object, isDarkMode ? 0.55 : 0.0875),
    {
      convertSRGBToLinear: true,
    }
  );

  return (
    <>
      <ThreeTransdimensionalRect id="resumeDocument_meet">
        <ThreeBox
          position={[0, 0, -theme.depth.card / 2]}
          scale={[1, 1, theme.depth.card]}
          material={contactMaterial}
        />
      </ThreeTransdimensionalRect>
      <ChipThree id="resumeDocument_meet_chip" depthOffset="foreground" />
      <ThreeTransdimensionalRect id="resumeDocument_page1_sideContentPipe">
        <ThreeModel
          id="pipe_attachment"
          position={[0, 0, 0.1 / 2]}
          scale={[1, 0.1, 1]}
          rotation={[-Math.PI / 2, 0, 0]}
          material={basicMaterial2}
        />
        <ThreeModel
          id="pipe_corner"
          position={[0, 0, 0.1 + 0.5 / 2]}
          scale={[1, 1, 0.5]}
          rotation={[0, 0, -Math.PI / 2]}
          material={basicMaterial3}
        />
        <ThreeModel
          id="pipe"
          position={[-1, 0, 0.1 + 0.5 / 2]}
          scale={[1, 1, 0.5]}
          rotation={[0, 0, Math.PI / 2]}
          material={basicMaterial3}
        />
      </ThreeTransdimensionalRect>
    </>
  );
};

export const ResumeMeet: FC = () => {
  const theme = useTheme();
  const invertedTheme = useInvertedTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const page1ThreeRect = useSelector(
    getOrCreateTransdimensionalRectSelector('resumeDocument_three1')
  );

  return (
    <ResumeSection
      sideContent={
        <TransdimensionalRect
          id="resumeDocument_page1_sideContentPipe"
          sx={{
            position: 'absolute',
            left: 0,
            top: 'calc((100% - 48px) / 2)',
            width: 48,
            height: 48,
          }}
        />
      }
    >
      <ThemeProvider theme={LIGHT_THEME}>
        <TransdimensionalRect
          id="resumeDocument_meet"
          sx={{
            padding: 1,
            marginBottom: 2,
            textAlign: 'center',
          }}
        >
          <Box
            component="div"
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              background: theme.palette.brandYellow.main,
              zIndex: 0,
            }}
          />
          <Box component="div" sx={{ position: 'relative', zIndex: 11 }}>
            <Typography
              variant="body1"
              color={theme.palette.brandYellow.contrastText}
            >
              You have open access to my calendar!&nbsp;&nbsp;I'm happy to
              connect.
            </Typography>
            <TransdimensionalRect
              id="resumeDocument_meet_chip"
              display="inline-block"
              sx={{
                marginTop: 1,
              }}
            >
              <TransdimensionalOffset
                z={theme.depth.chip}
                containerRect={page1ThreeRect}
                display="inline-block"
              >
                <a href="https://samuelgoodell.com/meet" target="_blank">
                  <Chip
                    background={theme.palette.text.primary}
                    endAdornment={
                      <OpenInNewOutlinedIcon
                        sx={{
                          position: 'relative',
                          top: -1.5,
                          transform: 'scale(1.05)',
                        }}
                      />
                    }
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      color={invertedTheme.palette.text.secondary}
                      sx={{ position: 'relative', top: -2 }}
                    >
                      https://
                    </Typography>
                    <Typography
                      component="span"
                      variant="body1"
                      color={
                        isDarkMode
                          ? theme.palette.common.black
                          : theme.palette.common.white
                      }
                      sx={{ position: 'relative', top: -2 }}
                    >
                      samuelgoodell.com/meet
                    </Typography>
                  </Chip>
                </a>
              </TransdimensionalOffset>
            </TransdimensionalRect>
          </Box>
        </TransdimensionalRect>
      </ThemeProvider>
    </ResumeSection>
  );
};
