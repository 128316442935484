import { Project } from '../../types';

import { PROJECT_SAMUELGOODELL_PORTFOLIO_WEB } from './samuelgoodell-portfolio-web';
import { PROJECT_SAMUELGOODELL_CALENDLY_PROXY } from './samuelgoodell-calendly-proxy';
import { PROJECT_SAMUELGOODELL_MULTILATERATION_WEB } from './samuelgoodell-multilateration-web';
import { PROJECT_SAMUELGOODELL_CLOCK_WEATHER_CARD_HUI_ICONS } from './samuelgoodell-clock-weather-card-hui-icons';
import { PROJECT_FOREVERDAUNT } from './foreverdaunt';
import { PROJECT_AIRBLOCK } from './airblock';
import { PROJECT_MANGANESE_CORE_KIT } from './manganese-core-kit';
import { PROJECT_HOMEBRIDGE_LIFX_MULTI } from './homebridge-lifx-multi';
import { PROJECT_HOMEBRIDGE_NANOLEAF_MULTI } from './homebridge-nanoleaf-multi';

export const PROJECTS: Project[] = [
  PROJECT_SAMUELGOODELL_PORTFOLIO_WEB,
  PROJECT_SAMUELGOODELL_CALENDLY_PROXY,
  PROJECT_SAMUELGOODELL_MULTILATERATION_WEB,
  PROJECT_SAMUELGOODELL_CLOCK_WEATHER_CARD_HUI_ICONS,
  PROJECT_AIRBLOCK,
  PROJECT_FOREVERDAUNT,
  PROJECT_HOMEBRIDGE_LIFX_MULTI,
  PROJECT_HOMEBRIDGE_NANOLEAF_MULTI,
  PROJECT_MANGANESE_CORE_KIT,
];
