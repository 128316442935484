import {
  FC,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  createContext,
  PropsWithChildren,
} from 'react';
import { Material, MeshBasicMaterial, MeshStandardMaterial } from 'three';
import { useTheme } from '@mui/material';
import {
  TransdimensionalUnitConversionServiceContext,
  TransdimensionalInteractionServiceContext,
} from '../../contexts';
import { BoxColorCoercible, ElementState } from '../../types';
import { ThreeBox, ThreeTransdimensionalRect } from '..';
import { useMaterial } from '../../hooks';
import { enhance, toColor } from '../../utilities';

const DEFAULT_STATE: ElementState = {
  disabled: false,
  hover: false,
  active: false,
};

export const TransdimensionalButtonThreeContext =
  createContext<ElementState>(DEFAULT_STATE);

export const TransdimensionalButtonThree: FC<
  PropsWithChildren<{
    id: string;
    depth?: number;
  }>
> = ({ id, children, ...props }) => {
  const theme = useTheme();
  const transdimensionalInteractionService = useContext(
    TransdimensionalInteractionServiceContext
  );

  const depth = props.depth || theme.depth.button;
  const [state, setState] = useState<ElementState>(DEFAULT_STATE);

  useEffect(() => {
    transdimensionalInteractionService.onChange(id, setState as any);
  }, [transdimensionalInteractionService, setState]);

  useEffect(() => {
    if (!state) return;
  }, [state]);

  return (
    <ThreeTransdimensionalRect id={id}>
      <object3D position={[0, 0, -depth / 2]} scale={[1, 1, depth]}>
        <TransdimensionalButtonThreeContext.Provider value={state}>
          {children}
        </TransdimensionalButtonThreeContext.Provider>
      </object3D>
    </ThreeTransdimensionalRect>
  );
};
