import { lighten } from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import { BRAND_COLOR_RED, BRAND_COLOR_ORANGE } from '../../constants';
import { Project } from '../../types';

export const PROJECT_SAMUELGOODELL_CLOCK_WEATHER_CARD_HUI_ICONS: Project = {
  id: '@samuelgoodell/clock-weather-card-hui-icons',
  name: 'Clock Weather Card HUI Icons',
  description:
    'Fork of [clock-weather-card](https://github.com/pkissling/clock-weather-card), but using icons consistent with other Home Assistant frontend components',
  status: 'complete',
  chip: {
    background: `linear-gradient(135deg, ${BRAND_COLOR_RED} 0%, ${lighten(
      BRAND_COLOR_ORANGE,
      0.15
    )} 100%)`,
    color: 'rgb(255, 255, 255)',
    content: '@samuelgoodell/clock-weather-card-hui-icons',
  },
  page: {
    tabs: [
      {
        label: 'Overview',
        page: {
          markdown: `\
### Objectives

- Show daily and hourly weather forecast, preserving the forked card behavior.
- Use icons consistent with the first-party HUI card components.

### Status

This project is complete and in maintenance.
`,
        },
      },
      {
        icon: <CodeOutlinedIcon />,
        label: 'Repository',
        href: 'https://github.com/samuelgoodell/clock-weather-card-hui-icons',
      },
    ],
  },
};
