import { Font, FontLoader } from 'three-stdlib';

export class ThreeFontsService {
  public static fetch(url: string): Promise<Font> {
    return new Promise<Font>((resolve, reject) => {
      const loader = new FontLoader();
      loader.load(
        url,
        resolve,
        (xhr: ProgressEvent) => {
          if (!xhr.total) return;

          console.log(
            `Progress loading '${url}': ${xhr.loaded} / ${
              xhr.total
            } (${Math.round((100 * xhr.loaded) / xhr.total)}%)`
          );
        },
        (error: ErrorEvent) => {
          console.error(`Error occurred while loading font '${url}':`);
          console.error(error);

          reject(error);
        }
      );
    });
  }
}
