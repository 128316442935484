import { Vector2, MathUtils } from 'three';
import { LayoutAnchor } from '../types';
import { getVisibleHeightAtDepth } from '../utilities';

export class TransdimensionalUnitConversionService {
  static multiplyFieldOfView(fovDegrees: number, coefficient: number) {
    const fovRadians = MathUtils.degToRad(fovDegrees);

    return MathUtils.radToDeg(
      2 * Math.atan(coefficient * Math.tan(fovRadians / 2))
    );
  }

  private anchorId: string;
  private anchor: LayoutAnchor;

  private windowSize: Vector2;
  private containerSize: Vector2;
  private rendererSize: Vector2;
  private fovDegrees: number;
  private fovCoefficient: number;
  private cameraDepth: number;

  constructor(
    anchorId: string,
    anchor: LayoutAnchor,
    windowSize: Vector2,
    containerSize: Vector2,
    rendererSize: Vector2,
    fovDegrees: number,
    fovCoefficient: number,
    cameraDepth: number
  ) {
    this.anchorId = anchorId;
    this.anchor = anchor;
    this.windowSize = windowSize;
    this.containerSize = containerSize;
    this.rendererSize = rendererSize;
    this.fovDegrees = fovDegrees;
    this.fovCoefficient = fovCoefficient;
    this.cameraDepth = cameraDepth;
  }

  public getAnchorId() {
    return this.anchorId;
  }

  public getAnchorVector2() {
    return new Vector2(this.anchor.x, this.anchor.y);
  }

  public getWindowSize() {
    return this.windowSize;
  }

  public getWindowWidth() {
    return this.windowSize.x;
  }

  public getWindowHeight() {
    return this.windowSize.y;
  }

  public get containerAspect() {
    return this.containerSize.x / this.containerSize.y;
  }

  public get rendererAspect() {
    return this.rendererSize.x / this.rendererSize.y;
  }

  public getFovCoefficient() {
    return this.fovCoefficient;
  }

  public getRendererWidth() {
    return this.rendererSize.x;
  }

  public getRendererHeight() {
    return this.rendererSize.y;
  }

  public getRendererSize() {
    return new Vector2(this.rendererSize.x, this.rendererSize.y);
  }

  public getContainerWidth() {
    return this.containerSize.x;
  }

  public getContainerHeight() {
    return this.containerSize.y;
  }

  public getContainerSize() {
    return this.containerSize;
  }

  public getVisibleHeightAtDepth(sceneDepth: number) {
    // compensate for cameras not positioned at z=0
    if (sceneDepth < this.cameraDepth) {
      sceneDepth -= this.cameraDepth;
    } else {
      sceneDepth += this.cameraDepth;
    }

    return getVisibleHeightAtDepth(this.fovDegrees, sceneDepth);
  }

  public getVisibleWidthAtDepth(sceneDepth: number) {
    const height = this.getVisibleHeightAtDepth(sceneDepth);

    return height * this.containerAspect;
  }

  public getVisibleSizeAtDepth(sceneDepth: number) {
    return new Vector2(
      this.getVisibleWidthAtDepth(sceneDepth),
      this.getVisibleHeightAtDepth(sceneDepth)
    );
  }

  public getRelativeScroll() {
    const windowScroll = new Vector2(window.scrollX, window.scrollY);

    return windowScroll
      .clone()
      .sub(this.getAnchorVector2())
      .add(this.windowSize.clone().sub(this.containerSize).divideScalar(2));
  }

  public getWindowCenterThree(): Vector2 {
    const relativeScroll = this.getRelativeScroll();
    const scrollRatio = relativeScroll.clone().divide(this.containerSize);
    const visibleSize = this.getVisibleSizeAtDepth(0);

    return scrollRatio
      .clone()
      .multiply(visibleSize)
      .multiply(new Vector2(1, -1));
  }

  public clientToThreeY(clientY: number, sceneDepth: number = 0) {
    const visibleHeight = this.getVisibleHeightAtDepth(sceneDepth);
    const scrollRatio = clientY / this.containerSize.y;

    return scrollRatio * visibleHeight;
  }

  public clientToThreeX(clientX: number, sceneDepth: number = 0) {
    const visibleWidth = this.getVisibleWidthAtDepth(sceneDepth);
    const scrollRatio = clientX / this.containerSize.x;

    return scrollRatio * visibleWidth;
  }

  public threeToClientX(threeX: number, sceneDepth: number = 0) {
    const visibleWidth = this.getVisibleWidthAtDepth(sceneDepth);

    return (threeX / visibleWidth) * this.containerSize.x;
  }
}
