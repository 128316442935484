import { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { TransdimensionalRect, NoWrap } from '../..';
import { ResumeSection, ResumeSectionLabel, ResumeDivider } from '.';

const RESUME_EXPERIENCE_EMS_DETAIL = false;

const ResumeList: FC<PropsWithChildren<{}>> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      component="ul"
      className="ResumeList-root"
      sx={{
        position: 'relative',
        paddingLeft: 2,
        margin: 0,
        listStyle: 'none',
        '& .ResumeList-root': {
          '&::before': {
            content: "''",
            position: 'absolute',
            left: `calc(${theme.spacing(-2)} + 2px)`,
            top: -12,
            width: 2,
            height: 'calc(100% - 2px)',
            backgroundColor: theme.palette.text.secondary,
          },
          '& > .ResumeListItem-root': {
            '&::before': {
              content: "''",
              position: 'absolute',
              left: `calc(${theme.spacing(-4)} + 2px)`,
              top: 16,
              width: `calc(${theme.spacing(2)} - 2px)`,
              height: 2,
              backgroundColor: theme.palette.text.secondary,
            },
            '&::after': {
              content: "''",
              position: 'absolute',
              left: theme.spacing(-2),
              top: 14,
              width: 6,
              height: 6,
              backgroundColor: 'transparent',
              border: '2px solid',
              borderColor: theme.palette.text.secondary,
            },
          },
        },
        '& > .ResumeListItem-root': {
          '&::after': {
            content: "''",
            position: 'absolute',
            left: theme.spacing(-2),
            top: 14,
            width: 6,
            height: 6,
            backgroundColor: theme.palette.text.secondary,
          },
        },
      }}
    >
      {children}
    </Box>
  );
};

const ResumeListItem: FC<PropsWithChildren<{ label: ReactNode }>> = ({
  label,
  children,
}) => {
  return (
    <Box
      component="li"
      className="ResumeListItem-root"
      sx={{ position: 'relative' }}
    >
      <Typography component="span" variant="body1">
        {label}
      </Typography>
      {children}
    </Box>
  );
};

const ResumeExperienceItem: FC<{
  id: string;
  organization: ReactNode;
  location: ReactNode;
  time: ReactNode;
  role: ReactNode;
  description: ReactNode;
}> = (props) => {
  return (
    <TransdimensionalRect
      id={`resumeDocument_experienceItem_${props.id}`}
      display="block"
      sx={{ padding: 0 }}
    >
      <Typography component="span" variant="body1" fontWeight="bold">
        {props.organization}
      </Typography>
      <Typography component="span" variant="body2">
        {' '}
        — {props.location} — {props.time}
      </Typography>
      <Typography variant="body1" fontWeight={600} fontStyle="italic">
        {props.role}
      </Typography>
      {props.description}
    </TransdimensionalRect>
  );
};

export const ResumeExperienceSectionPage1: FC = () => {
  const theme = useTheme();

  return (
    <ResumeSection
      sideContent={
        <ResumeSectionLabel>
          <NoWrap>Experience</NoWrap>
        </ResumeSectionLabel>
      }
    >
      <Stack spacing={2}>
        <ResumeExperienceItem
          id="ryanBrothersAmbulance"
          organization="Ryan Brothers Ambulance"
          location="Madison, WI"
          time="February 2024 to October 2024"
          role="Paramedic"
          description={
            <ResumeList>
              <ResumeListItem label="Administering basic and advanced life support to patients in pre-hospital and inter-facility settings." />
              <ResumeListItem label="Utilizing state of the art equipment including Alaris Infusion pumps, Vapotherm, BiPAP, and blood products in providing high quality patient care." />
            </ResumeList>
          }
        />
        <ResumeExperienceItem
          id="crossPlainsEms"
          organization="Cross Plains EMS"
          location="Cross Plains, WI"
          time="June 2023 to January 2024"
          role="Emergency Medical Technician"
          description={
            <ResumeList>
              <ResumeListItem label="Responding to 911 calls and providing emergency medical care as an ambulance crew member with an AEMT level service." />
              <ResumeListItem label="Assisting with department needs including monthly training and community outreach." />
            </ResumeList>
          }
        />
      </Stack>
    </ResumeSection>
  );
};

export const ResumeExperienceSectionPage2: FC = () => {
  return (
    <ResumeSection sideContent={null}>
      <Stack spacing={2}>
        <ResumeExperienceItem
          id="dottid"
          organization="Dottid"
          location="Remote"
          time="June 2022 to March 2023"
          role="Senior Software Engineer"
          description={
            <ResumeList>
              <ResumeListItem label="Acted as lead frontend software engineer for a data visualization suite developed for the commercial real estate industry." />
              <ResumeListItem label="Leveraged modern web technologies to transform customer lease data into property- and suite-level metrics such as occupancy, utilization, and churn." />
            </ResumeList>
          }
        />
        <ResumeExperienceItem
          id="undigital"
          organization="UnDigital"
          location="Remote"
          time="June 2021 to March 2022"
          role="Software Engineer"
          description={
            <ResumeList>
              <ResumeListItem label="Spearheaded development of an internal admin application used by customer support that facilitated new client onboarding, integration with new client systems, user management, and troubleshooting orders." />
              <ResumeListItem label="Worked with an early-revenue startup engineering team to develop the company's software controlled on-demand printed marketing solution." />
            </ResumeList>
          }
        />
        <ResumeExperienceItem
          id="gettyImages"
          organization="Getty Images"
          location="Madison, WI"
          time="June 2019 to March 2021"
          role="Software Engineer"
          description={
            <ResumeList>
              <ResumeListItem label="Significantly improved Electron-based application performance utilizing program performance profiling." />
              <ResumeListItem label="Reduced reliance on external video transcoding service by improving in-house functionality around uploading, storing, and processing images and videos in AWS." />
              <ResumeListItem label="Developed and maintained software applications using React + Typescript, Node.js, Ruby on Rails, MySQL, and ElasticSearch as a full stack engineer to enable a world-class media ingestion pipeline." />
              <ResumeListItem label="Implemented automated testing solutions to ensure everything that shipped was reliable and bug-free, including unit tests, browser-based testing with Cypress, and continuous integration in GitLab CI." />
            </ResumeList>
          }
        />
        <ResumeExperienceItem
          id="hardinDesignAndDevelopment"
          organization="Hardin Design and Development"
          location="Madison, WI"
          time="August 2018 to March 2019"
          role="Web Applications Developer"
          description={
            <ResumeList>
              <ResumeListItem label="Developed and maintained PHP web applications used by major commercial real estate and insurance firms, working as a consultant to identify and meet project goals." />
              <ResumeListItem label="Built tools using React to visualize business intelligence data and help client executives make educated real estate purchasing decisions." />
            </ResumeList>
          }
        />
      </Stack>
    </ResumeSection>
  );
};
