import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

export const ResumeSection: FC<
  PropsWithChildren<{ sideContent?: ReactNode }>
> = ({ sideContent, children }) => {
  return (
    <Box component="section" sx={{ display: 'flex', position: 'relative' }}>
      <Box
        component="div"
        sx={{
          position: 'relative',
          width: 62,
          minHeight: '100%',
          flexShrink: 0,
          flexGrow: 0,
        }}
      >
        {sideContent}
      </Box>
      <Box
        component="div"
        sx={{ position: 'relative', flexGrow: 1, paddingLeft: 4 }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const ResumeSectionLabel: FC<
  PropsWithChildren<{
    sx?: SxProps<Theme>;
  }>
> = ({ sx, children }) => {
  return (
    <Typography
      variant="body2"
      fontSize="1.25rem"
      fontWeight={700}
      lineHeight="48px"
      sx={[
        {
          // background: 'url(/images/grainy.svg)',
          // backgroundClip: 'text',
          // textFillColor: 'transparent',
          transform: 'rotate(-90deg) translateX(-100%)',
          transformOrigin: 'top left',
          width: 'min-content',
          paddingRight: '9px',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Typography>
  );
};
