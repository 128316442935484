import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FontLoader, Font } from 'three-stdlib';

export type ThreeFontsState = {
  threeFontsById: {
    [fontId: string]: Font;
  };
};

const INITIAL_STATE: ThreeFontsState = {
  threeFontsById: {},
};

export const threeFontsSlice = createSlice({
  name: 'threeFonts',
  initialState: INITIAL_STATE,
  reducers: {
    setThreeFont: (
      state,
      action: PayloadAction<{
        id: string;
        font: Font;
      }>
    ) => {
      const { id, font } = action.payload;

      state.threeFontsById = {
        ...state.threeFontsById,
        [id]: font,
      };
    },
  },
});
